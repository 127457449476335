
import React from "react"
import { Link} from "gatsby"
import RetmesToplogo from "../images/retmes_logo.svg"
class TopHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true
    };
    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  render() {
    return (<div className='top-header'>
      <Link to='/'><img src={RetmesToplogo} className={this.state.isTop ? "retmes-top-center" : "retmes-top-left"} alt="retmes top logo" /></Link>
    </div>
    );
  }
}

export default TopHeader
