import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PaperWork from "../images/refs/paperwork.png"
import { Row, Container, Col } from 'react-bootstrap/'
import RetmesApps from "../images/retmes-kurumsal.svg"
import TopHeader from "../components/top-header"

import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const RetmesHak = ({ intl }) => {

  return <Layout>
    <TopHeader />
    <SEO title={intl.formatMessage({ id: "about_title_h1" })} />
    <Container>
      <h1 className='page-title'><FormattedMessage id="about_title_h1" /></h1>
      <Row className=''  >
        <Col sm className='retmes-lp-ml-col'>
          <img style={{ width: '40rem' }} src={RetmesApps} alt="Retmes Hakkında" />
        </Col>
      </Row>
      <Row className='home-row-2'  >
        <Col className='retmes-lp-ml-col-2' >
          <h2><FormattedMessage id="about_content_title_h1" /></h2>
          <p><FormattedMessage id="about_content_desc_p" /></p>
          <br></br>
          <p><FormattedMessage id="about_content_desc_p_line_2" /></p>
        </Col>
      </Row>
      <Row>
        <Col sm style={{ alignSelf: 'center', marginBottom: '0' }} className='head-col-1'  >
          <h3><FormattedMessage id="about_solution_title" /></h3>
          <div />
          <img src={PaperWork} style={{ width: '250px', margin: '3em' }} alt="retmes paperwork" />
          <div />
        </Col>
      </Row>
    </Container>
  </Layout>
}

export default injectIntl(RetmesHak)
